import { observable, action, makeObservable } from 'mobx';
import request from './../../bin/httpRequest';

const filterFormat = (res) => {
  const chars = [];
  let attrs = [];
  if (res && res.data && res.data.data) {
    const { listFacets } = res.data.data;
    // console.log('listFacets: ', listFacets);
    if (listFacets !== undefined && listFacets.length) {
      for (let i = 0, len = listFacets.length; i < len; i += 1) {
        const { identifier, name, atrributes } = listFacets[i];
        // Object creation for characteristic filter
        const re = /filt-chars-/gi;
        const description = identifier || name;

        if (description.match(re)) {
          const labels = {};
          for (let i2 = 0, len2 = atrributes.length; i2 < len2; i2 += 1) {
            if (atrributes[i2].label !== '1') {
              atrributes[i2].label = description.replace('filt-chars-', '');
              if (!labels[atrributes[i2].label]) {
                chars.push(atrributes[i2]);
                labels[atrributes[i2].label] = atrributes[i2].label;
              }
            }
          }
        } else if (!name.match('OfferPrice_USD')) {
          attrs.push(listFacets[i]);
        }
      }
    }
  }

  if (chars.length) {
    attrs.push({
      name: 'filt-caracteristicas',
      atrributes: chars,
    });
  }

  if (res && res.data && res.data.data && res.data.data.skus) {
    attrs = {
      attrs,
      items: res.data.data.skus,
    };
  }

  return attrs;
};

class Facets {
  facetResult = observable.map();
  facetsResponse = observable.map();

  constructor() {
    makeObservable(this, {
      facetResult: observable,
      facetsResponse: observable,
      allFacets: action,
      facetsUpdate: action
    });
  }

  allFacets(id) {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_WCAAS}${process.env.NEXT_PUBLIC_MERCHANT}/productlookup/facets/byCategoryId/${id}`,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'options', success: false };
      if (!res.error) {
        const filterFormatResult = filterFormat(res);

        callback = Object.assign({}, callback, { data: filterFormatResult, success: true });
      } else {
        callback = Object.assign({}, callback, { error: res.error });
      }
      return callback;
    });
  }

  facetsUpdate(id, query, addons) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_WCAAS}${process.env.NEXT_PUBLIC_MERCHANT}/${id}/productlookup/facets`,
      data: query,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'facetUpdate', success: false };
      if (!res.error) {
        let excludeAdOns;
        if (res.data.data.skus) excludeAdOns = res.data.data.skus.filter(e => !addons.some(x => x.item_part_number === e.part_number));
        res.data.data.skus = excludeAdOns;
        const filterFormatResult = filterFormat(res);
        callback = Object.assign({}, callback, { data: filterFormatResult.attrs || [], items: filterFormatResult.items || [], success: true });
      } else {
        callback = Object.assign({}, callback, { error: res.error });
      }
      return callback;
    });
  }
}

export default new Facets();
