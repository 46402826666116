import React from 'react';
import PropTypes from 'prop-types';
// import { xclose } from '../../helpers/svg';

const ModalNotification = ({ children, subclass, isSecondModal }) => (
  // <div className="ui--modal-notification active">
  <div className={`ui--modal-notification active ${isSecondModal ? 'ui--modal-second' : ''}`}>
    <div className={subclass} style={{ textAlign: 'left' }}>
      <section>
        {children}
        {/* <a className="close" onClick={toggle}>{xclose}</a> */}
      </section>
    </div>
  </div>
);

ModalNotification.propTypes = {
  children: PropTypes.node.isRequired,
  // toggle: PropTypes.func.isRequired,
};

export default ModalNotification;
