'use client';
import '~/fonts/font-claro.css';
import { handleToogleFooter } from '~/components/Headers/handlers';

export default function FooterLinks({ layoutData, info }) {
  const socialMedia = [];
  const list = [];
  const listMobile = [];
  if (layoutData) {
    for (let i = 0; i < layoutData.MenuFooter.length; i += 1) {
      let item = layoutData.MenuFooter[i];
      let subItems = item.Hijos.map((item2, linkKey) => {
        return (
          <li key={linkKey}>
            <a href={item2.URL} target={item2.Target}>
              {item2.Titulo}
            </a>
          </li>
        );
      });
      let element = (
        <ul key={i}>
          <li>
            <b>{item.Titulo}</b>
          </li>
          {subItems}
        </ul>
      );
      list.push(element);

      let elementM = (
        <div key={i} className={`footer-links-element element-${item.Titulo}`}>
          <div
            onClick={() => {
              handleToogleFooter(item.Titulo);
            }}
            className="footer-links-element-tittle"
          >
            <a>{item.Titulo}</a>
            <i className="ico-chevron-down"></i>
          </div>
          <div className={`footer-links-element-list list-${item.Titulo}`}>{subItems}</div>
        </div>
      );
      listMobile.push(elementM);
    }
    for (let i = 0; i < layoutData.RedesSociales.length; i += 1) {
      let item = layoutData.RedesSociales[i];
      let social = (
        <a key={i} href={item.URL} target={item.Target} rel="noopener noreferrer nofollow">
          <img src={`${process.env.NEXT_PUBLIC_ESPOTS}/headerfooter/${item.Clase}.svg`} alt="" />
        </a>
      );
      socialMedia.push(social);
    }
  }
  return (
    <div className="footer--links">
      <section>
        <div className='footer--links-cont'>
          {list}
          <div className="footer--seal">
            <a href={info?.selloGarantia?.link} target="_blank" rel="noopener noreferrer nofollow">
              <img src={`${process.env.NEXT_PUBLIC_ESPOTS}/media/${info?.selloGarantia?.imagen}`} alt="" />
            </a>
          </div>
        </div>
        <div className='footer--links-cont-mobile'>
          {listMobile}
          <div className="footer--seal">
            <a href={info?.selloGarantia?.link} target="_blank" rel="noopener noreferrer nofollow">
              <img src={`${process.env.NEXT_PUBLIC_ESPOTS}/media/${info?.selloGarantia?.imagen}`} alt="" />
            </a>
          </div>
        </div>

        <div className="footer--social">
          <p className="footer--social-tittle">Redes Sociales</p>
          <div className="footer--social-icons">{socialMedia}</div>
        </div>
      </section>
    </div>
  );
}
