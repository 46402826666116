import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { numberChars } from '~/helpers/char-restriction';
import useStores from '~/store/StoreHook';

import ModalNotification from '~/components/ui/ModalNotification';
import Notification from '~/containers/Views/Notification/Notification.ui';

const Token = observer((props) => {
  const { orderStore } = useStores();

  const [loading, setLoading] = useState(false);
  const [errorToken, setErrorToken] = useState(null);
  const [reenviar, setReenviar] = useState(null);

  function reenviarToken(e) {
    e.preventDefault();
    props.sendToken().then(() => {
      setReenviar('Código reenviado.');
      setTimeout(() => setReenviar(null), 2000);
    });
  }

  function validateToken() {
    const query = {
      validarToken: {
        UserProfileID: props.email,
        MobileNumber: '',
        LineOfBusiness: '1',
        Token: props.valorToken,
      },
    };
    setLoading(true);
    orderStore.validarToken(query).then((res) => {
      setLoading(false);
      if (res.success && (res.data?.data?.validarTokenResponse || res.data?.data?.validarToken)?.AcknowledgementIndicator === 'SUCCESS') {
        props.valid();
        props.handleData();
      } else {
        setErrorToken('Código  no válido.');
      }
    });
  }

  return (
    <ModalNotification subclass="register-token">
      <div>
        <nav>
          <span className="red-color btn-close" onClick={props.onClose}><i className="fas fa-times fa-2x" /></span>
        </nav>
        <div className="token-header">
          <h3>Verifica tu correo electrónico</h3>
          <p>Te enviamos un código a tu correo electrónico.</p>
          <p>Por favor ingresa el código aquí:</p>
        </div>
        <input
          type="text"
          maxLength={6}
          value={props.valorToken}
          onKeyPress={numberChars}
          onChange={props.onChange}
        />
        <button type="button" className={`token-btn ${loading && 'disabled'}`} onClick={validateToken} disabled={loading}>{loading && (<i className="fas fa-circle-notch fa-spin" />)} Verificar</button>
        {errorToken && <Notification title={errorToken} />}
        <div className="token-footer">
          <button type="button" className="red-color token-reenviar" onClick={reenviarToken}>Reenviar código</button>
          {reenviar && <Notification title={reenviar} />}
        </div>
      </div>
    </ModalNotification>
  );
});

export default Token;
