import { observable, action, makeObservable } from 'mobx';

// Helpers
import request from '~/bin/httpRequest';
import analytics from '~/helpers/analytics';
import { getIframeValue, setValueToIframe } from '~/helpers/multisesion';
import sessionStore, { cookies, guestSession } from '~/store/Sessions/Sessions';

export const genericFunction = (options, sessionIdDisable, actionCallback) => request.genericHandler(options, sessionIdDisable).then((res) => {
  let callback = { action: actionCallback, success: false };
  if (!res.error) {
    callback = {
      ...callback,
      data: res.data,
      success: true,
    };
  } else {
    callback = {
      ...callback,
      error: res.error,
    };
  }
  return callback;
});

export const renewal = (query) => {
  const options = {
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/renewal`,
    data: query,
  };

  return genericFunction(options, null, 'renewal');
};

const reqLogin = (loading, res) => {
  const options = {
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person`,
    loading,
  };

  return request.genericHandler(options).then(res);
};

class AccountStore {
  // @observable addCart = observable.map();
  orders = observable.map();

  person = observable.map();

  userGuest = true;

  resetPass = observable.map();

  passReq = observable.map();

  profile = observable.map();

  constructor() {
    makeObservable(this, {
      orders: observable,
      person: observable,
      userGuest: observable,
      resetPass: observable,
      passReq: observable,
      profile: observable,
      dataPerson: action,
      ordersPerson: action,
      profilePerson: action,
      resetPassword: action,
      passRequested: action,
      syncAddress: action,
      personBillingAddress: action,
      personShippingAddress: action
    });

    this.sessionResponse = this.sessionResponse.bind(this);
  }

  sessionResponse(res) {
    let callback = { action: 'dataPerson', success: false };
    if (!res.error) {
      callback = { ...callback, data: res.data, success: true };
      callback.userGuest = !this.userGuest;
      analytics.userId();
    } else {
      callback = { ...callback, error: res.error };
      analytics.logout();
    }

    this.person = callback;
    return callback;
  }

  async dataPerson(loading) {
    const authToken = await getIframeValue();
    if (!authToken) {
      return this.sessionResponse({
        error: true,
      });
    }
    let response = await reqLogin(loading, this.sessionResponse);
    if (!response.success && authToken !== '0') {
      if (!cookies.get('Session-Id')) {
        const guest = await guestSession();
        if (guest && guest.data && guest.data.data) {
          cookies.set('Session-Id', guest.data.data.session_id, {
            path: '/',
          });
        }
      }
      const loginRes = await sessionStore.personLogin({
        email: '',
        password: '',
        token: {
          access_token: authToken,
        },
      });
      if (!loginRes.success) {
        setValueToIframe('');
        return this.sessionResponse({
          error: true,
        });
      }
      response = await reqLogin(loading, this.sessionResponse);
    }
    return response;
  }

  ordersPerson(limit) {
    let paramLimit = '';
    if (limit) {
      paramLimit = `?limit=${limit}`;
    }
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/orderhistory${paramLimit}`,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'ordersPerson', success: false };
      if (!res.error) {
        callback = { ...callback, data: res.data, success: true };
      } else {
        callback = { ...callback, error: res.error };
        // alert('error');
      }
      this.orders = callback;
      return callback;
    });
  }

  profilePerson(query, router) {
    const options = {
      method: 'PUT',
      url: `${process.env.NEXT_PUBLIC_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person`,
      data: query,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'profilePerson', success: false };
      if (!res.error) {
        callback = { ...callback, data: res.data, success: true };
        if (router) router.history.push('/account');
      } else {
        callback = { ...callback, error: res.error };
        // alert('error');
      }
      this.profile = callback;
      return callback;
    });
  }

  resetPassword(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_CUSTOM_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/resetPassword`,
      data: query,
    };
    return request.genericHandler(options).then((res) => {
      let callback = { action: 'resetPassword', success: false };
      if (!res.error) {
        callback = { ...callback, data: res.data, success: true };
      } else {
        callback = { ...callback, error: res.error };
      }
      this.resetPass = callback;
      return callback;
    });
  }

  passRequested(query) {
    const options = {
      method: 'PUT',
      url: `${process.env.NEXT_PUBLIC_CUSTOM_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/passwordreset`,
      data: query,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'passRequested', success: false };
      if (!res.error) {
        callback = { ...callback, data: res.data, success: true };
      } else {
        callback = { ...callback, error: res.error };
        // alert('error');
      }
      this.passReq = callback;
      return callback;
    });
  }

  syncAddress() {
    const options = {
      method: 'PUT',
      url: `${process.env.NEXT_PUBLIC_CUSTOM_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/passwordreset`,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'syncAddress', success: false };
      if (!res.error) {
        callback = { ...callback, data: res.data, success: true };
        callback.userGuest = !this.userGuest;
      } else {
        callback = { ...callback, error: res.error };
        // alert('error');
      }
      this.person = callback;
      return callback;
    });
  }

  async personBillingAddress(query) {
    const options = {
      method: 'PUT',
      url: `${process.env.NEXT_PUBLIC_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/address/billing`,
      data: query,
    };

    return genericFunction(options, null, 'personBillingAddress');
  }

  async personShippingAddress(query) {
    const options = {
      method: 'PUT',
      url: `${process.env.NEXT_PUBLIC_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/address/shipping`,
      data: query,
    };

    return genericFunction(options, null, 'personShippingAddress');
  }

  async quotationPlanInfo(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/quotationPlan/info`,
      data: query,
    };

    return genericFunction(options, null, 'quotationPlanInfo');
  }
}

export default new AccountStore();
