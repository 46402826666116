import React from 'react';
import { Link } from '#/renderer/router';
import $ from 'jquery';
import { Icon } from '@iconify/react';
import infoOutlineRounded from '@iconify/icons-material-symbols/info-outline-rounded';

// utilities
import { iconCheck, icoCustomer, icoPortability } from '~/helpers/svg';
import orderStore from '~/store/Orders/Cart';
import { useConfigMC } from '~/store/Query/api';

export const Customer = () => {
  const { data } = useConfigMC();
  const closeModal = () => {
    $('.modal--customer').removeClass('active');
    $('.modal').fadeOut();
  };
  const switchModal = () => {
    $('.modal--customer').removeClass('active');
    $('.modal--user').addClass('active');
    // analytics.checkout(1, 'login');
  };

  return (
    <section className="modal--content">
      <header>
        <i>{icoCustomer}</i>
        <h2>¿Eres cliente Claro?</h2>
      </header>
      <nav>
        <Link to="/checkout" onClick={closeModal} id="no-soy-cliente">No soy cliente</Link>
        <Link to="/cart?mode=login" onClick={switchModal} id="si-soy-cliente">Sí soy cliente</Link>
      </nav>
      {data?.ok && data.body.sesion?.inicio && (
        <div className="session">
          <Icon icon={infoOutlineRounded} color="#0097a9" width="16" height="16" />
          <div dangerouslySetInnerHTML={{ __html: data.body.sesion.inicio }} />
        </div>
      )}
    </section>
  );
};

export const Portability = () => (
  <section className="modal--content">
    <header>
      <i>{icoPortability}</i>
      <h2>¿Cómo quieres adquirir tu equipo?</h2>
    </header>
    <nav>
      <a>Línea nueva</a>
      <a>Portabilidad</a>
    </nav>
    <a className="back">Regresar</a>
  </section>
);

export const Renewal = () => (
  <section className="modal--content">
    <header>
      <i>{icoPortability}</i>
      <h2>¿Cómo quieres adquirir tu equipo?</h2>
    </header>
    <nav>
      <a>Línea nueva</a>
      <a>Portabilidad</a>
    </nav>
    <section>
      <header>
        <i>{iconCheck}</i>
        <div>
          <h4>Estado de renovación: Con un costo adicional</h4>
          <p>*Aún no termina tu contrato</p>
        </div>
      </header>
      <ul>
        <li><b>Plan:</b> <span>Sin límte</span></li>
        <li><b>Plazo:</b> <span>24 Mensualidades</span></li>
        <li><b>Último pago:</b> <span>$12,000.00</span></li>
      </ul>
      <aside>
        <p className="price">
          <span className="symbol">$</span>
          <span>4,350.</span>
          <span className="symbol">00</span>
          <span className="legend">Costo por renovación</span>
        </p>
      </aside>
    </section>
    <a className="back">Regresar</a>
  </section>
);

export const RenewalCase = () => {
  const closeModal = () => {
    $('.modal--renewal-case').removeClass('active');
    $('.modal').fadeOut();
  };

  return (
    <section className="modal--content-renewal">
      <header>
        <h2>Tu solicitud de renovación está en proceso</h2>
        <picture>
          <img src={`${process.env.NEXT_PUBLIC_ESPOTS}/media/iLike.png`} alt="" />
        </picture>
      </header>
      <h3>Tu número de gestión es</h3>
      <h4 id="number-case">numberCase</h4>
      <p>Gracias por preferirnos</p>
      <p>enviamos un correo de confirmación</p>
      <p>el proceso puede llegar a tardar de 24 a 72 horas</p>
      <nav>
        <aside className="renewal--continue">
          <Link to="/" onClick={closeModal}><span>Seguir navegando  </span></Link>
        </aside>
      </nav>
    </section>
  );
};

export const Number = () => (
  <section className="modal--number">
    <header>
      <h2>Trae tu número a Claro</h2>
      <p>Ingresa tu número para verificar la disponibilidad</p>
    </header>
    <form>
      <label>
        <input type="text" placeholder="Ingresa los 10 dígitos de tu número" />
        <i>{iconCheck}</i>
      </label>
      <p>Tú número esta listo y disponible para transferirlo a Claro</p>
    </form>
    <aside>
      <a>Continuar</a>
    </aside>
    <a className="back">Regresar</a>
  </section>
);

export const TipoCompra = () => {
  const closeModal = () => {
    orderStore.validateCartTrigger();
    $('.modal--tipo-compra').removeClass('active');
    $('.modal').fadeOut();
  };
  const switchModal = () => {
    $('.modal--tipo-compra').removeClass('active');
    $('.modal--validate-number').addClass('active');
  };

  return (
    <section className="modal--content-tipo-compra">
      <header>
        <h2>Indícanos que tipo de compra deseas realizar:</h2>
      </header>
      <div className="modal--content-tipo-compra-nav">
        <a onClick={closeModal} id="nuevo-postpago">Deseo contratar un Plan Postpago nuevo</a>
        <a onClick={switchModal} id="migracion">Deseo cambiar mi número Claro Prepago a un Plan Postpago</a>
      </div>
    </section>
  );
};
