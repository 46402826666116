import React from 'react';

// Components
import Notification from '~/containers/Views/Notification/Notification.ui';

// Helpers
import { emailChars } from '~/helpers/char-restriction';
import { useConfigMC } from '~/store/Query/api';

const UI = ({ loginForm, handleChange, handleSubmit, modalType, error }) =>{
  const { data } = useConfigMC();
  return (
  <section className="modal--form">
    <header>
      <h2>Inicia Sesión</h2>
      <p dangerouslySetInnerHTML={{ __html: data?.body.sesion?.registro }}/>
    </header>
    <form name="login" onSubmit={handleSubmit}>
      <p>Ingresa Usuario:</p>
      <label htmlFor="loginEmail">
        <input
          id="loginEmail"
          name="loginEmail"
          type="text"
          placeholder="ejemplo@email.com"
          onKeyPress={emailChars}
          value={loginForm.email}
          onChange={(e) => { handleChange(e, 'internalData.email'); }}
        />
      </label>
      <p>Ingresa Contraseña:</p>
      <label htmlFor="loginPass">
        <input
          id="loginPass"
          name="loginPass"
          type="password"
          placeholder="Ingresa tu contraseña"
          value={loginForm.password}
          onChange={(e) => { handleChange(e, 'internalData.password'); }}
        />
      </label>
      <button type="submit"><span>Inicia Sesión</span></button>
    </form>
    <aside>
      <a id="olvidaste-contrasenia" className="trigger-password" onClick={() => { modalType('onPassReset'); }}>¿Olvidaste tu contraseña?</a>
    </aside>
    <aside>
      <p>¿Aún no la tienes?</p> <a id="crear-cuenta" className="trigger-register" onClick={() => { modalType('onRegister'); }}>Crea una cuenta</a>
    </aside>
    {(error) ? <Notification title={error} /> : <div />}
  </section>
  )
};

export default UI;
