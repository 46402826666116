import he from 'he';
import React from 'react';

export default function SeccionesHeaderMobile({ getSeccionesHeader }) {
  return (
    <>
      {getSeccionesHeader.map((i) => {
        let outputMobile;
        if (
          i.URL === 'https://www.claro.com.sv/personas/servicios' ||
          i.Descripcion.toLowerCase().trim() === 'servicios'
        ) {
          outputMobile = '';
        } else if (
          i.Descripcion.toLowerCase().trim() === 'tienda' ||
          i.URL === 'https://tiendaenlinea.claro.com.sv'
        ) {
          outputMobile = '';
        } else {
          outputMobile = (
            <a
              key={`${i.Descripcion}-${i.Unico}`}
              href={he.decode(i.URL)}
              className="nav--menu-title"
            >
              <span>{he.decode(i.Descripcion)}</span>
              <span className="mobile-icon fa-angle-right" />
            </a>
          );
        }
        return outputMobile;
      })}
    </>
  );
}
